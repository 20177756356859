import { Route, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage/LandingPage';
import CheckListForm from './Pages/CheckListForm/CheckListForm';
import './App.css';

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/checkListForm" element={<CheckListForm />} />
     </Routes>
  );
}

export default App;
