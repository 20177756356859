import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Rate,
  Button,
  message,
  Image,
  Card,
  DatePicker,
  Switch,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import jsPDF from "jspdf";
import HomaPageImge from "../../Assets/HomePage.png";
import { FaDownload } from "react-icons/fa";

const { TextArea } = Input;

const InspectionItem = ({ label, formName, form }) => {
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState(false);
  const [image, setImage] = useState(null);

  const handleChange = (checked) => {
    setChecked(checked);
    setTouched(true);
    if (!checked) setImage(null);
    form.setFieldsValue({ [formName]: checked });
  };

  const handleUpload = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        form.setFieldsValue({ [`${formName}Image`]: reader.result });
        resolve();
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <div className="inspection-item">
      <div className="d-flex align-items-center justify-content-center">
        <Switch
          checked={checked}
          onChange={handleChange}
          className={
            touched ? (checked ? "switch-on" : "switch-off") : "switch-default"
          }
        />
      </div>
      {touched && (checked ? "Satisfied" : "Unsatisfied")}
      {!checked && touched && (
        <ImgCrop rotate>
          <Upload
            beforeUpload={handleUpload}
            showUploadList={false}
            accept="image/*"
          >
            <Button icon={<UploadOutlined />} className="upload-btn">
              Upload Image
            </Button>
          </Upload>
        </ImgCrop>
      )}
      {image && (
        <Image
          src={image}
          alt="Unsatisfied item"
          style={{ width: 100, marginTop: 10 }}
        />
      )}
    </div>
  );
};

const VehicleInspectionForm = () => {
  const [form] = Form.useForm();
  const [submitted, setSubmitted] = useState(false);
  const [formData, setFormData] = useState(null);

  const items = [
    "Brakes",
    "Tire Condition",
    "Emergency Brakes",
    "Window Condition",
    "Horn",
    "Fluids (Coolant)",
    "Fluids (Oil)",
    "Fluids (Brake)",
    "Brake Lights",
    "Reverse Light",
    "Reverse Alarm",
    "AC Condition",
    "Emergency Lights",
    "Left & Right Indicators",
    "Seat Belts",
    "Spare Tire",
    "Fire Extinguisher",
    "First Aid Kit",
    "Head Lights (Low)",
    "Head Light (High)",
    "Wiper (Water)",
    "Wiper (Function)",
  ];

  useEffect(() => {
    const savedFormData = JSON.parse(
      localStorage.getItem("vehicleInspectionForm")
    );
    if (savedFormData) {
      form.setFieldsValue(savedFormData);
      setFormData(savedFormData);
    }
  }, [form]);

  const handleSave = () => {
    const values = form.getFieldsValue();
    localStorage.setItem("vehicleInspectionForm", JSON.stringify(values));
    setFormData(values);
    message.success("Form data saved successfully!");
  };

  const handleCancel = () => {
    form.resetFields();
    localStorage.removeItem("vehicleInspectionForm");
    setFormData(null);
    message.info("Form cleared.");
  };

  const handleSubmit = (values) => {
    setSubmitted(true);
    setFormData(values);
    message.success("Form submitted successfully!");
  };

  const handleDownloadReport = () => {
    if (!formData) return;

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text("Vehicle Inspection Report", 105, 20, null, null, "center");

    doc.setFontSize(12);
    doc.text(`Date: ${formData.date}`, 20, 30);
    doc.text(
      `Vehicle Number: ${formData.vehicleNumber || "N/A"}`,
      180,
      30,
      null,
      null,
      "right"
    );
    doc.text(`Name: ${formData.fullName}`, 20, 40);

    const startY = 50;
    let currentY = startY;

    items.forEach((item, index) => {
      const response = formData[item] ? "Satisfied" : "Unsatisfied";
      doc.text(`${index + 1}. ${item}: ${response}`, 20, currentY);
      currentY += 10;
    });

    currentY += 0;
    doc.setFontSize(12);
    doc.text(`Additional Comments:`, 20, currentY + 5);
    doc.text(`${formData.additionalComments || "N/A"}`, 65, currentY + 5);

    doc.text(`Overall Rating:`, 20, currentY + 10);
    doc.text(
      `${formData.overallRating || "N/A"} Stars`,
      78,
      currentY + 10,
      null,
      null,
      "right"
    );

    doc.save("vehicle_inspection_report.pdf");
  };

  return (
    <div>
      <Image src={HomaPageImge} />

      {submitted ? (
        <div className="row download-container">
          <h2 className="download-container-heading my-4 text-green">
            Download Report
          </h2>
          <div className="col-4"></div>
          <Card className="u-shadow col-4">
            <div className="download-container-title text-dark text-center mb-3">
              Thank you for completing the checklist!
            </div>
            <div className="download-container-subTitle text-dark text-center">
              Click the button below to download your PDF report for rating the
              vehicle.
            </div>
            <Button
              type="primary"
              className="mt-4 bg-green"
              icon={<FaDownload />}
              onClick={handleDownloadReport}
            >
              Download Report
            </Button>
          </Card>
        </div>
      ) : (
        <div>
          <div className="text-green title mt-5 mb-3">
            Welcome to Drive Dubai
          </div>
          <Card className="u-shadow form-container mb-5">
            <h2 className="form-title text-green mb-3">Rate Vehicle</h2>
            <Form
              layout="horizontal"
              form={form}
              onFinish={handleSubmit}
              className="pdf-content row pb-0"
            >
              <div className="col-6">
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[{ required: true, message: "Date is required" }]}
                >
                  <DatePicker
                    className="w-100"
                    placeholder="DD/MM/YYYY"
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </div>

              <div className="col-6">
                <Form.Item
                  name="vehicleNumber"
                  label="Vehicle Number"
                  rules={[
                    { required: true, message: "Vehicle Number is required" },
                  ]}
                >
                  <Input placeholder="AA 53310" />
                </Form.Item>
              </div>
              <div className="form-info my-3">
                Review each item, mark 'Satisfactory' or 'Unsatisfactory' for
                our team to address.
              </div>
              {items.map((item, index) => (
                <Form.Item
                  key={index}
                  name={item}
                  className="review-items"
                  label={`${index + 1}. ${item}`}
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <InspectionItem
                    label={item}
                    formName={item}
                    form={form}
                    index={index}
                  />
                </Form.Item>
              ))}
            </Form>
            <Form
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              className="pdf-content row pt-0"
            >
              <Form.Item
                name="fullName"
                label="Full Name"
                rules={[{ required: true, message: "Full Name is required" }]}
              >
                <Input placeholder="Full name" />
              </Form.Item>
              <Form.Item
                name="additionalComments"
                label="Please Provide Additional Comments, Details for Defaults, or Note for Status of Issues"
              >
                <TextArea rows={4} placeholder="Comments" />
              </Form.Item>

              <Form.Item
                name="overallRating"
                label="Overall Rating"
                rules={[{ required: true, message: "Rating is required" }]}
              >
                <Rate style={{ color: "#FFCC00" }} />
              </Form.Item>

              <div className="form-buttons d-flex justify-content-evenly">
                <button
                  className="btn btn-md bg-dark-gray text-white button-text"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-md bg-green-gradient text-white button-text"
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  htmltype="submit"
                  className="btn btn-md bg-green-gradient text-white button-text"
                >
                  Submit
                </button>
              </div>
            </Form>
          </Card>
          <div className="bg-red w-100" style={{ height: "1rem" }}></div>
          <div className="bg-green w-100" style={{ height: "3rem" }}></div>
        </div>
      )}
    </div>
  );
};

export default VehicleInspectionForm;
