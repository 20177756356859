import React, { useState } from "react";
import { FaApple, FaFacebook, FaLongArrowAltRight } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import HomaPageImge from "../../Assets/HomePage.png";
import { Card, Form, Image, Input, notification } from "antd";
import { Link, useNavigate } from "react-router-dom";

function LandingPage() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [showSpinner] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = () => {
    setErrors({ email: "", password: "" });

    if (!formData.email || !formData.password) {
      setErrors({
        email: !formData.email ? "Email is required" : "",
        password: !formData.password ? "Password is required" : "",
      });
      return;
    }

    if (formData.email === "Admin" && formData.password === "Admin@123") {
      notification.success({
        message: "Login Successful",
        description: "You have logged in successfully!",
      });
      navigate("/checkListForm");
    } else {
      notification.error({
        message: "Login Failed",
        description: "Incorrect username or password",
      });
    }
  };

  return (
    <div className="">
      <Image src={HomaPageImge} />
      <div className="Login-container u-shadow row mt-5">
        <h2 className="text-center text-green fw-bold page-title mb-5">Login to Drive Dubai</h2>
        <div className="col-12 col-lg-3 col-xl-4 col-xxl-4"></div>
        <div className="col-12 col-lg-3 col-xl-4 col-xxl-4">
          <Card className="login-card u-shadow mb-5">
            <h4 className="text-center mb-5">Login with</h4>
            <div className="d-flex align-items-center justify-content-center gap-3">
              <div className="border border-1 rounded-3 p-4">
                <Link to="/">
                  <FaFacebook style={{ fontSize: "30px" }} fill="#316FF6" />
                </Link>
              </div>
              <div className="border border-1 rounded-1 p-4">
                <Link to="/">
                  <FaApple style={{ fontSize: "30px" }} fill="#000" />
                </Link>
              </div>
              <div className="border border-1 rounded-1 p-4">
                <Link to="/">
                  <FcGoogle style={{ fontSize: "30px" }} />
                </Link>
              </div>
            </div>
            <div className="text-center my-4">or</div>
            <Form layout="vertical" size="large" onFinish={handleSubmit}>
              <Form.Item
                validateStatus={errors.email ? "error" : ""}
                help={errors.email}
              >
                <Input
                  name="email"
                  value={formData.email}
                  placeholder="Email"
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors.password ? "error" : ""}
                help={errors.password}
              >
                <Input.Password
                  className="input-password-container"
                  name="password"
                  value={formData.password}
                  placeholder="Password"
                  onChange={handleChange}
                />
                <div className="text-dark mt-1">Forgot Password?</div>
              </Form.Item>
              <Form.Item>
                <button
                  className="bg-green-gradient btn text-white w-100"
                  htmlType="submit"
                >
                  Login <FaLongArrowAltRight />
                </button>
              </Form.Item>
              <div className="text-center text-dark-gray d-flex justify-content-center">
                Don't have an account?&nbsp;
                <div className="text-dark">Sign up</div>
              </div>
            </Form>
            {showSpinner && <div className="spinner">Loading...</div>}
          </Card>
        </div>
        <div className="col-12 col-lg-3 col-xl-4"></div>
      </div>
      <div className="bg-red w-100" style={{ height: "1rem" }}></div>
      <div className="bg-green w-100" style={{ height: "3rem" }}></div>
    </div>
  );
}

export default LandingPage;
